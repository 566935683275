<template>
  <div class="the-header">
    <div class="begin">
      <div
        class="content flex flex-wrap items-center md:justify-between justify-center py-4 lg:px-24 px-4 border-b"
      >
        <div class="logo cursor-pointer">
          <icon :data="icons.logo" original width="275" height="75"></icon>
        </div>
        <div class="social flex items-center">
          <div class="media-icon cursor-pointer mr-6" @click="openLinkedin()">
            <icon :data="icons.linkedin" original width="40" height="40"></icon>
          </div>
          <div class="media-icon cursor-pointer mr-6" @click="openFacebook()">
            <icon :data="icons.facebook" original width="40" height="40"></icon>
          </div>
          <div class="media-icon cursor-pointer" @click="openTwitter()">
            <icon :data="icons.twitter" original width="40" height="40"></icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "../../assets/icons/ic_logo.svg";
import facebook from "../../assets/icons/ic_facebook.svg";
import twitter from "../../assets/icons/ic_twitter.svg";
import linkedin from "../../assets/icons/ic_linkedin.svg";
export default {
  name: "the-header",
  data() {
    return {
      icons: { logo, facebook, twitter, linkedin },
    };
  },
  methods: {
    openFacebook() {
      window.open(
        "https://www.facebook.com/remaapp/?ref=br_rs&_rdc=1&_rdr",
        "_blank"
      );
    },
    openLinkedin() {
      window.open(
        "https://www.linkedin.com/company/rema-r%C3%A9seau-d-echange-entre-m%C3%A9decins-d-afrique/?originalSubdomain=bj",
        "_blank"
      );
    },
    openTwitter() {
      window.open("https://twitter.com/rema_app", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";
</style>
