<template>
  <div class="home">
    <div class="begin">
      <div class="content h-screen">
        <!-- HEADER COMPONENT -->
        <the-header></the-header>

        <div class="body lg:px-24 px-4 lg:py-16 py-12">
          <div class="body-content flex flex-wrap items-center">
            <div class="part-one lg:w-1/2 w-full md:mb-16 lg:mb-0">
              <div
                class="title font-bold xl:text-5xl text-2xl md:text-4xl leading-loose"
              >
                {{ $t("continueFormation") }}
              </div>
              <br />
              <div class="form">
                <div class="form-content">
                  <div class="label mb-4">
                    {{ $t("enterAttestationNumber") }}
                  </div>
                  <div class="input-bloc flex items-center">
                    <div class="input mr-3 w-2/3">
                      <input
                        v-model="number"
                        type="text"
                        :placeholder="$t('enterNumber')"
                        class="ipt w-full px-4 py-4 border border-gray-300 hover:border-[#16B853] focus:border-[#16B853] rounded-md"
                      />
                    </div>
                    <div class="save-button ml-3">
                      <button-base
                        :label="$t('verifyButton')"
                        @click="verifyAttestation()"
                      ></button-base>
                    </div>
                  </div>
                  <div v-if="error" class="error text-red-500 mt-3">
                    {{ errorMessage }}
                  </div>
                </div>
              </div>
              <br />
              <div class="accreditation mt-16">
                <div class="font-semibold">Certificats accrédités par</div>
                <div class="flex items-start mt-6">
                  <img :src="images.logo" class="w-56 pr-8" />
                  <img :src="images.code" class="w-40 px-8" />
                  <img :src="images.accreditation" class="w-56 pl-8" />
                </div>
              </div>
            </div>
            <div class="part-two lg:w-1/2 w-full">
              <div
                class="picture bg-no-repeat bg-contain bg-center w-full h-96"
                v-bind:style="{
                  'background-image': 'url(' + images.woman + ')',
                }"
              ></div>
            </div>
          </div>
        </div>

        <!-- FOOTER COMPONENT -->
        <the-footer></the-footer>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/helper/the-header";
import woman from "../assets/images/img_woman.webp";
import TheFooter from "@/components/helper/the-footer";
import ButtonBase from "@/components/helper/add/button-base";
import http from "@/plugins/https";
import apiRoutes from "@/router/api-routes";
import logo from "@/assets/images/img_qahe-logo.jpg";
import code from "@/assets/images/img_qr-code.png";
import accreditation from "@/assets/images/img_Program-Accreditation-400x400.png";
export default {
  name: "HomeView",
  components: { ButtonBase, TheFooter, TheHeader },
  data() {
    return {
      images: { woman, logo, code, accreditation },
      number: "",
      error: false,
      errorMessage: "",
    };
  },
  methods: {
    verifyAttestation() {
      if (this.number === "") {
        this.error = true;
        this.errorMessage = this.$t("enterNumber");
      } else {
        this.error = false;
        http
          .get(apiRoutes.baseURL + apiRoutes.generateFile, {
            params: {
              code: this.number,
            },
          })
          .then((response) => {
            // console.log(response);
            window.open(response.url, "_blank");
          })
          .catch((error) => {
            // console.log(error);
            if (error.status === 404) {
              this.error = true;
              this.errorMessage = this.$t("errorNotFount");
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";
.svg-fill {
  fill: transparent;
}
.picture {
  height: 31rem;
}
.label {
  color: #667085;
}
.title {
  line-height: 1.25;
}
</style>
