<template>
  <div class="the-footer">
    <div class="begin">
      <div class="content py-8 lg:px-24 px-4 text-center">
        <div class="title font-medium text-lg">{{ $t("contactTeam") }}</div>
        <div class="contact mt-2">+229 96508130 / contact@remaapp.com</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "the-footer",
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";
.content {
  background: #f7fafd;
}
.contact {
  color: #667085;
}
</style>
